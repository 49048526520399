<template>
  <div>
    <v-row
      class="col-12 pa-0 mx-0 my-4"
      no-gutters
      justify="center"
      align="center">
      <!-- time -->
      <week-stats-info
        stateName="Time"
        :stateValue="totalTimeFormatted" />
      <v-divider
        vertical
        inset
        class="my-2"></v-divider>

      <!-- exercises -->
      <week-stats-info
        stateName="Exercises"
        :stateValue="`${exercicesNumber}`" />
      <v-divider
        vertical
        inset
        class="my-2"></v-divider>

      <!-- streak -->
      <week-stats-info
        stateName="Streak"
        :stateValue="`${streak} DAYS`" />
    </v-row>
    <div
      id="chart-container"
      style="width: 100%; height: 150px"
      class="mb-16">
      <canvas
        ref="chartCanvas"
        style="height: 100%"></canvas>
    </div>
  </div>
</template>

<script>
  import WeekStatsMixin from '@/components/profile/week-stats/WeekStats.mixin.vue';
  export default {
    mixins: [WeekStatsMixin],
  };
</script>
